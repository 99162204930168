.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &__ornament {
    @include margin-bottom(100, 40);

    @include width(1680, 320);

    background: center;
  }

  &__ornament_ofar {
    height: 40px;

    background-image: url('../assets/dec-1.svg');
  }

  &__ornament_sesam {
    height: 63px;

    background-image: url('../assets/dec-2.svg');
  }

  &__ornament_zosya {
    @include width(1920, 375);
    height: 41px;

    background-image: url('../assets/dec-3.svg');
  }
}
