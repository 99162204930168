.header {
  @include padding-top(105, 52);

  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    @include margin-bottom(72, 36);

    @include width(170, 85);
    @include height(170, 85);

    // animation: logo 110s linear 0s infinite normal;
  }

  &__videos {
    @include margin-bottom(40, 20);

    @include width(1670, 250);

    display: flex;
    justify-content: space-evenly;

    @media (max-width: 769px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__video {
    @include margin-bottom(40, 25);

    @include width(690, 300);
    @include height(370, 160);

    border-radius: 10px;
  }

  &__decor {
    @include margin-bottom(80, 40);

    display: flex;
    align-items: center;
  }

  &__ornament {
    @include width(622, 261);
    height: 40px;

    background: url('../assets/dec-1.svg');
  }

  &__ornament_left {
    background-position: right;
  }

  &__ornament_right {
    background-position: left;
  }

  &__decor-text {
    @include margin-right(15, 8);
    @include margin-left(15, 8);

    @include width(814, 100);

    @include font(24, 12);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: $vine;
  }
}

@keyframes logo {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
