.event {
  @include margin-top(60, 30);
  @include margin-bottom(40, 20);

  position: relative;

  display: flex;
  justify-content: flex-start;

  @media (max-width: 1060px) {
    flex-direction: column;
    align-items: center;
  }

  &__img-container {
    height: 100%;
  }

  &__img {
    @include width(500, 200);

    @media (max-width: 1060px) {
      @include margin-bottom(40, 30);

      width: 100%;
    }
  }

  &__info {
    @include padding-left(100, 15);

    display: flex;
    flex-direction: column;
  }

  &__title {
    @include margin-bottom(60, 30);

    @include font(50, 20);
    font-weight: bold;
  }

  &__desc {
    @include margin-bottom(60, 30);

    @include font(24, 14);
    @include line-height(30, 18);
  }

  &__link {
    text-decoration: none;
    text-overflow: ellipsis;
    color: rgb(85, 155, 247);

    transition: color 0.3s;

    &:hover {
      color: rgb(48, 136, 252);
    }
  }

  &__delete {
    @include width(36, 18);
    @include height(36, 18);

    position: absolute;
    top: 0;
    right: 0;

    @include font(36, 18);

    color: $red;

    transition: transform 0.3s;

    &:hover {
      transform: rotate(90deg);

      cursor: pointer;
    }
  }

  &__no-event {
    @include margin-top(30, 15);

    @include font(25, 14);
    font-weight: 500;
    text-align: center;
  }
}
