.blackout {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);
}
