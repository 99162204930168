.videos {
  @include padding-top(105, 52);

  display: flex;
  flex-direction: column;
  align-items: center;

  &__videos {
    @include margin-bottom(40, 20);
  
    @include width(1670, 250);
    //display: flex;
    justify-content: space-evenly;
    @media (max-width: 769px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__video {
    @include margin-bottom(40, 25);
    @include margin-left(35, 0);    
    @include width(690, 300);
    @include height(370, 160);

    border-radius: 10px;
  }
}
