.btn {
  @include padding-top(20, 12);
  @include padding-right(36, 18);
  @include padding-bottom(20, 12);
  @include padding-left(36, 18);

  
  @include margin-top(69, 33);

  @include width(344, 172);
  @include height(74, 37);

  border-radius: 45px;

  cursor: pointer;

  &__text {
    @include font(26, 13);
    font-weight: bold;
    text-align: center;
  }
}
