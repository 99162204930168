.events {
  display: flex;
  flex-direction: column;
  justify-content: center; 

    &__videos {

    // @include width(1024, 250);

    display: flex;
    justify-content: space-evenly;

    @media (max-width: 769px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__video {
    @include margin-top(40, 25);

    @include width(690, 300);
    @include height(370, 160);

    border-radius: 10px;
  }

}

