.forms {
  @include padding-top(40, 20);
  @include padding-right(40, 20);
  @include padding-bottom(40, 20);
  @include padding-left(40, 20);

  position: relative;

  display: flex;
  flex-direction: column;

  background-color: $fff;

  border: 1px solid $grey;
  border-radius: 20px;
  box-shadow: 0 0 8px rgba($color: #000000, $alpha: 0.2);

  @media (max-height: 900px) {
    @include height(900, 300);
    
    overflow-y: auto;
  }

  &__title {
    @include margin-bottom(40, 20);

    @include font(16, 10);
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &__close {
    @include width(20, 18);
    @include height(20, 18);

    position: absolute;
    @include top(20, 10);
    @include right(20, 10);

    @include font(20, 18);

    color: $red;

    transition: transform 0.3s;

    &:hover {
      transform: rotate(90deg);

      cursor: pointer;
    }
  }

  &__label {
    @include margin-bottom(12, 10);

    @include font(12, 10);
    font-weight: 700;
    text-transform: uppercase;
  }

  &__input {
    @include margin-bottom(16, 10);
    padding: 5px 10px;

    @include width(500, 200);
    @include height(40, 20);

    border: 2px solid $dark;
    border-radius: 10px;

    outline: none;
  }

  &__fieldset {
    @include margin-bottom(20, 10);

    border: none;
  }

  &__choose {
    @include margin-bottom(12, 10);

    display: flex;
    align-items: center;

    @include font(12, 10);
    font-weight: 600;
  }

  &__checkbox {
    margin-right: 4px;
  }

  &__radio {
    margin-right: 4px;
  }

  &__fake-checkbox input[type='checkbox'],
  &__fake-checkbox input[type='radio'] {
    @include margin-bottom(6, 4);

    position: relative;

    opacity: 0;
  }

  &__fake-checkbox label::before {
    content: '';

    height: 14px;
    width: 14px;

    display: inline-block;

    position: absolute;
    top: 0;
    left: -16px;

    border: 1px solid;
    border-radius: 50%;
  }

  &__fake-checkbox label::after {
    content: '';

    height: 8px;
    width: 8px;

    display: inline-block;

    position: absolute;
    top: 4px;
    left: -12px;

    background-color: $yell;
    border-radius: 50%;
  }

  &__fake-checkbox label {
    position: relative;
  }

  &__fake-checkbox input[type='checkbox'] + label::after,
  &__fake-checkbox input[type='radio'] + label::after {
    content: none;
  }

  &__fake-checkbox input[type='checkbox']:checked + label::after,
  &__fake-checkbox input[type='radio']:checked + label::after {
    content: '';
  }

  &__fake-label {
    padding-left: 6px;

    @include font(12, 10);
    font-weight: 600;
  }

  &__line {
    @include margin-top(20, 10);
    @include margin-bottom(20, 10);

    width: 100%;

    border: none;
    border-top: 1px solid $dark;
  }

  &__textarea {
    padding: 5px 10px;
    @include margin-bottom(30, 15);

    @include width(500, 200);
    @include height(150, 60);

    border: 2px solid $dark;
    border-radius: 10px;

    outline: none;
  }

  &__submit {
    @include padding-top(11, 6);
    @include padding-right(30, 15);
    @include padding-bottom(11, 6);
    @include padding-left(30, 15);
    margin: 0 auto;

    width: 50%;

    @include font(12, 10);
    font-weight: 700;

    color: $fff;
    background-color: $red;

    border: none;
    border-radius: 45px;

    transition: transform 0.3s, opacity 0.3s;

    &:hover {
      transform: scale(1.02);

      opacity: 0.9;
      cursor: pointer;
    }
  }
}
