.success {
  @include padding-top(40, 20);
  @include padding-right(40, 20);
  @include padding-bottom(40, 20);
  @include padding-left(40, 20);

  background-color: $fff;

  border: none;
  border-radius: 20px;

  &__head {
    @include margin-bottom(20, 10);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    @include font(20, 14);
    font-weight: 700;
    color: rgb(86, 218, 119);
  }

  &__close {
    @include width(20, 18);
    @include height(20, 18);

    @include font(20, 18);
    font-weight: 700;

    transition: transform 0.3s;

    &:hover {
      transform: rotate(90deg);

      cursor: pointer;
    }
  }

  &__info {
    @include width(400, 200);

    @include font(20, 14);
  }
}
