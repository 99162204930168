.nav {
  @include margin-bottom(60, 30);

  &__list {
    @include width(1670, 300);

    display: flex;
    justify-content: space-evenly;

    list-style: none;

    @media (max-width: 468px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item:nth-child(2) {
    @media (min-width: 769px) {
      margin-left: 65px;
    }
  }

  &__link {
    @include font(18, 9);
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: $dark;

    transition: color 0.3s;

    &:hover {
      color: $vine;
    }
  }
}
